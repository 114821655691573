<template>
  <div>
    <f-row>
      <f-col
        class="required"
        md="12"
      >
        <f-select
          v-model="bug.status"
          :label="$t('labels.status')"
          :options="bugStatuses"
          rules="required"
          value-type="string"
        />
      </f-col>
      <f-col
        v-if="
          bug.status === bugStatus.TESTING ||
            bug.status === bugStatus.TEST_APPROVED
        "
        md="12"
      >
        <f-row>
          <f-col>
            <label for="selectRepo">{{ $t('labels.repository') }}</label>
            <f-select
              v-model="bug.staging"
              :key="'staging'"
              :data-params="{
                projectId: bug.project.id,
                hasDeploymentUrl: true
              }"
              data-url="/projects/repositories"
              :placeholder="$t('labels.selectRepo')"
              :settings="{ allowClear: false }"
              @change="onChangeRepositoryUrl($event, 'staging')"
            />
          </f-col>
          <f-col
            class="required"
            md="6"
          >
            <f-input
              v-model="bug.stagingLink"
              :label="$t('labels.stagingLink')"
              rules="required"
            >
              <template #append>
                <a
                  :href="bug.stagingLink"
                  target="_blank"
                >
                  <f-button
                    icon="external-link"
                    variant="info"
                  />
                </a>
              </template>
            </f-input>
          </f-col>
        </f-row>
        <f-input
          v-model="bug.stagingNote"
          :label="$t('labels.stagingNote')"
          multiline
        />
      </f-col>
      <f-col
        v-if="bug.status === bugStatus.DONE"
        md="12"
      >
        <f-row>
          <f-col>
            <label for="selectRepo">{{ $t('labels.repository') }}</label>
            <f-select
              v-model="bug.prod"
              :key="'prod'"
              :data-params="{
                projectId: bug.project.id,
                hasDeploymentUrl: true
              }"
              data-url="/projects/repositories"
              :placeholder="$t('labels.selectRepo')"
              :settings="{ allowClear: false }"
              @change="onChangeRepositoryUrl($event, 'prod')"
            />
          </f-col>
          <f-col
            class="required"
            md="6"
          >
            <f-input
              v-model="bug.prodLink"
              :label="$t('labels.prodLink')"
              rules="required"
            >
              <template #append>
                <a
                  :href="bug.prodLink"
                  target="_blank"
                >
                  <f-button
                    icon="external-link"
                    variant="info"
                  />
                </a>
              </template>
            </f-input>
          </f-col>
        </f-row><f-input
          v-model="bug.prodNote"
          :label="$t('labels.prodNote')"
          multiline
        />
      </f-col>
      <f-col
        v-if="bug.status === bugStatus.REJECTED"
        md="12"
      >
        <f-input
          v-model="bug.rejectedReason"
          :label="$t('labels.rejectedReason')"
          multiline
        />
      </f-col>
    </f-row>
  </div>
</template>

<script>
import { bugStatus } from '@/data/enums'

export default {
  props: {
    bug: { type: Object }
  },
  data() {
    return {
      bugStatus,
      bugStatuses: [
        { id: bugStatus.WAITING, name: this.$i18n.t('labels.waitingBug') },
        { id: bugStatus.APPROVED, name: this.$i18n.t('labels.approvedBug') },
        {
          id: bugStatus.IN_PROGRESS,
          name: this.$i18n.t('labels.inProgress')
        },
        { id: bugStatus.TESTING, name: this.$i18n.t('labels.testing') },
        {
          id: bugStatus.TEST_APPROVED,
          name: this.$i18n.t('labels.testApproved')
        },
        { id: bugStatus.REJECTED, name: this.$i18n.t('labels.rejected') },
        { id: bugStatus.DONE, name: this.$i18n.t('labels.done') }
      ]
    }
  },
  methods: {
    async onChangeRepositoryUrl(id, type) {
      const { stagingUrl, productionUrl } = await this.$http.get(
        `/projects/repositories/${id}`
      )

      type === 'staging'
        ? (this.bug.stagingLink = stagingUrl)
        : (this.bug.prodLink = productionUrl)
    }
  }
}
</script>
