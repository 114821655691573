var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-card',[(!_vm.bug.deletedAt)?_c('div',[_c('f-row',[_c('f-col',{staticClass:"required",attrs:{"md":"12"}},[_c('f-input',{attrs:{"disabled":!_vm.userHasEditPermission('subject'),"label":_vm.$t('labels.subject'),"rules":"required"},model:{value:(_vm.bug.subject),callback:function ($$v) {_vm.$set(_vm.bug, "subject", $$v)},expression:"bug.subject"}})],1),_c('f-col',{staticClass:"required",attrs:{"md":"12"}},[_c('f-input',{attrs:{"disabled":!_vm.userHasEditPermission('content'),"label":_vm.$t('labels.content'),"multiline":true,"rows":"8","rules":"required"},model:{value:(_vm.bug.content),callback:function ($$v) {_vm.$set(_vm.bug, "content", $$v)},expression:"bug.content"}})],1),_c('f-col',{attrs:{"md":"12"}},[_c('f-switch',{attrs:{"default-value":_vm.bug.isUrgent,"disabled":!_vm.userHasEditPermission('isUrgent'),"false-label":_vm.$t('labels.normal'),"true-label":_vm.$t('labels.urgent')},on:{"change":function($event){return _vm.onChangeStatus(_vm.bug.isUrgent)}},model:{value:(_vm.bug.isUrgent),callback:function ($$v) {_vm.$set(_vm.bug, "isUrgent", $$v)},expression:"bug.isUrgent"}})],1),_c('f-card',{staticClass:"table-view",attrs:{"header":_vm.$t('labels.fileAndImages')}},[_c('f-upload',{attrs:{"accept":"*/*","disabled":!_vm.userHasEditPermission(),"drag-drop":"","multiple":true,"placeholder":_vm.$t('labels.uploadAttachment')},on:{"input":_vm.uploadAttachment}}),_c('f-datatable',{ref:"bugAttachmentDatatable",staticClass:"bugAttachmentTable",attrs:{"id":"bugAttachmentTable","columns":_vm.attachmentColumns,"data":_vm.bug.attachments,"features":{
            delete: function () { return !_vm.bug.id || _vm.user.role === _vm.userRole.ADMIN; }
          },"options":{
            showFullscreen: false,
            showColumns: false,
            pagination: false
          }},on:{"delete":_vm.onDeleteAttachment}})],1),_c('f-card',{ref:"bugNoteCard",attrs:{"header":"Notlar"}},[_vm._v(" "+_vm._s(_vm.bug.files)+" "),_c('f-row',[_c('f-col',{staticClass:"d-flex"},[_c('b-form-textarea',{ref:"bugNote",staticClass:"form-control form-group w-90 mr-1",attrs:{"disabled":!_vm.userHasEditPermission(),"placeholder":_vm.$t('messages.addNote'),"size":"sm","type":"text-area"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.onClickAddNote.apply(null, arguments)}},model:{value:(_vm.addNoteText),callback:function ($$v) {_vm.addNoteText=$$v},expression:"addNoteText"}}),_c('f-button',{attrs:{"disabled":!_vm.userHasEditPermission(),"icon":"plus","label":_vm.$t('labels.addNote'),"type":"submit","variant":"warning"},on:{"click":function($event){return _vm.onClickAddNote()}}})],1)],1),_c('b-list-group',{ref:"noteList"},_vm._l((_vm.bug.notes),function(note,index){return _c('b-list-group-item',{key:index,staticClass:"flex-column align-items-start",class:{
              'list-group-item-success': !note.id
            }},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h6',{staticClass:"mb-1 w-30"},[_c('b',[_vm._v(_vm._s(note.createdBy.fullName))])]),_c('small',[_c('b',[_vm._v(_vm._s(_vm.formatDateTime(note.createdAt)))])])]),_c('div',{staticStyle:{"position":"relative"}},[_c('p',{ref:index,refInFor:true,staticClass:"w-85",style:({
                  whiteSpace: 'pre-line'
                })},[_vm._v(" "+_vm._s(index === _vm.isShowMore ? note.text : note.text.split(/\r\n|\r|\n/).length > 4 ? note.text .split(/\r\n|\r|\n/) .splice(0, 4) .join('\n') : note.text.slice(0, 360))+" "),(
                    note.text.length > 360 ||
                      note.text.split(/\r\n|\r|\n/).length > 4
                  )?_c('span',{staticClass:"ml-2 align-self-end text-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.readMore(index)}}},[_vm._v(" "+_vm._s(_vm.isShowMore === index ? _vm.$t('labels.hide') : '...' + _vm.$t('labels.readMore'))+" ")]):_vm._e()]),_c('div',{staticStyle:{"position":"absolute","right":"5px","top":"2px"}},[(!_vm.bug.id || _vm.user.role === _vm.userRole.ADMIN || !note.id)?_c('f-button',{staticClass:"ml-2",attrs:{"disabled":!_vm.userHasEditPermission(),"icon":"times","pill":"","size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.onClickDeleteNote(index, note.id)}}}):_vm._e()],1)])])}),1)],1),_c('f-modal',{ref:"modalUrgentMessage",attrs:{"cancel-disabled":"","header-bg-variant":"warning","header-text":"center","size":"sm","submit-disabled":"","title":_vm.$t('labels.warning')},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('f-button',{attrs:{"block":"","label":_vm.$t('labels.ok'),"variant":"warning"},on:{"click":_vm.closeUrgent}})]},proxy:true}],null,false,1060434252)},[_c('div',{staticClass:"d-block text-center"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('messages.urgentMessage'))+" ")])])])],1)],1):_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(("Bu hata " + (_vm.bug.updatedBy.fullName) + " tarafından " + (this.$flex.dateService.getDateTime(_vm.bug.deletedAt)) + " tarihinde silinmiştir"))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }