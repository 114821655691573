var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f-row',[_c('f-col',{staticClass:"required",attrs:{"md":"12"}},[_c('f-select',{attrs:{"label":_vm.$t('labels.status'),"options":_vm.bugStatuses,"rules":"required","value-type":"string"},model:{value:(_vm.bug.status),callback:function ($$v) {_vm.$set(_vm.bug, "status", $$v)},expression:"bug.status"}})],1),(
        _vm.bug.status === _vm.bugStatus.TESTING ||
          _vm.bug.status === _vm.bugStatus.TEST_APPROVED
      )?_c('f-col',{attrs:{"md":"12"}},[_c('f-row',[_c('f-col',[_c('label',{attrs:{"for":"selectRepo"}},[_vm._v(_vm._s(_vm.$t('labels.repository')))]),_c('f-select',{key:'staging',attrs:{"data-params":{
              projectId: _vm.bug.project.id,
              hasDeploymentUrl: true
            },"data-url":"/projects/repositories","placeholder":_vm.$t('labels.selectRepo'),"settings":{ allowClear: false }},on:{"change":function($event){return _vm.onChangeRepositoryUrl($event, 'staging')}},model:{value:(_vm.bug.staging),callback:function ($$v) {_vm.$set(_vm.bug, "staging", $$v)},expression:"bug.staging"}})],1),_c('f-col',{staticClass:"required",attrs:{"md":"6"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.stagingLink'),"rules":"required"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('a',{attrs:{"href":_vm.bug.stagingLink,"target":"_blank"}},[_c('f-button',{attrs:{"icon":"external-link","variant":"info"}})],1)]},proxy:true}],null,false,3976977648),model:{value:(_vm.bug.stagingLink),callback:function ($$v) {_vm.$set(_vm.bug, "stagingLink", $$v)},expression:"bug.stagingLink"}})],1)],1),_c('f-input',{attrs:{"label":_vm.$t('labels.stagingNote'),"multiline":""},model:{value:(_vm.bug.stagingNote),callback:function ($$v) {_vm.$set(_vm.bug, "stagingNote", $$v)},expression:"bug.stagingNote"}})],1):_vm._e(),(_vm.bug.status === _vm.bugStatus.DONE)?_c('f-col',{attrs:{"md":"12"}},[_c('f-row',[_c('f-col',[_c('label',{attrs:{"for":"selectRepo"}},[_vm._v(_vm._s(_vm.$t('labels.repository')))]),_c('f-select',{key:'prod',attrs:{"data-params":{
              projectId: _vm.bug.project.id,
              hasDeploymentUrl: true
            },"data-url":"/projects/repositories","placeholder":_vm.$t('labels.selectRepo'),"settings":{ allowClear: false }},on:{"change":function($event){return _vm.onChangeRepositoryUrl($event, 'prod')}},model:{value:(_vm.bug.prod),callback:function ($$v) {_vm.$set(_vm.bug, "prod", $$v)},expression:"bug.prod"}})],1),_c('f-col',{staticClass:"required",attrs:{"md":"6"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.prodLink'),"rules":"required"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('a',{attrs:{"href":_vm.bug.prodLink,"target":"_blank"}},[_c('f-button',{attrs:{"icon":"external-link","variant":"info"}})],1)]},proxy:true}],null,false,2133224056),model:{value:(_vm.bug.prodLink),callback:function ($$v) {_vm.$set(_vm.bug, "prodLink", $$v)},expression:"bug.prodLink"}})],1)],1),_c('f-input',{attrs:{"label":_vm.$t('labels.prodNote'),"multiline":""},model:{value:(_vm.bug.prodNote),callback:function ($$v) {_vm.$set(_vm.bug, "prodNote", $$v)},expression:"bug.prodNote"}})],1):_vm._e(),(_vm.bug.status === _vm.bugStatus.REJECTED)?_c('f-col',{attrs:{"md":"12"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.rejectedReason'),"multiline":""},model:{value:(_vm.bug.rejectedReason),callback:function ($$v) {_vm.$set(_vm.bug, "rejectedReason", $$v)},expression:"bug.rejectedReason"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }