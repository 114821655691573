<template>
  <f-card>
    <div v-if="!bug.deletedAt">
      <f-row>
        <f-col
          class="required"
          md="12"
        >
          <f-input
            v-model="bug.subject"
            :disabled="!userHasEditPermission('subject')"
            :label="$t('labels.subject')"
            rules="required"
          />
        </f-col>
        <f-col
          class="required"
          md="12"
        >
          <f-input
            v-model="bug.content"
            :disabled="!userHasEditPermission('content')"
            :label="$t('labels.content')"
            :multiline="true"
            rows="8"
            rules="required"
          />
        </f-col>


        <f-col md="12">
          <f-switch
            v-model="bug.isUrgent"
            :default-value="bug.isUrgent"
            :disabled="!userHasEditPermission('isUrgent')"
            :false-label="$t('labels.normal')"
            :true-label="$t('labels.urgent')"
            @change="onChangeStatus(bug.isUrgent)"
          />
        </f-col>
        <f-card
          class="table-view"
          :header="$t('labels.fileAndImages')"
        >
          <f-upload
            accept="*/*"
            :disabled="!userHasEditPermission()"
            drag-drop
            :multiple="true"
            :placeholder="$t('labels.uploadAttachment')"
            @input="uploadAttachment"
          />
          <f-datatable
            id="bugAttachmentTable"
            ref="bugAttachmentDatatable"
            class="bugAttachmentTable"
            :columns="attachmentColumns"
            :data="bug.attachments"
            :features="{
              delete: () => !bug.id || user.role === userRole.ADMIN
            }"
            :options="{
              showFullscreen: false,
              showColumns: false,
              pagination: false
            }"
            @delete="onDeleteAttachment"
          />
        </f-card>
        <f-card
          ref="bugNoteCard"
          header="Notlar"
        >
          {{ bug.files }}
          <f-row>
            <f-col class="d-flex">
              <b-form-textarea
                v-model="addNoteText"
                ref="bugNote"
                class="form-control form-group w-90 mr-1"
                :disabled="!userHasEditPermission()"
                :placeholder="$t('messages.addNote')"
                size="sm"
                type="text-area"
                @keydown.enter.exact.prevent="onClickAddNote"
              />

              <f-button
                :disabled="!userHasEditPermission()"
                icon="plus"
                :label="$t('labels.addNote')"
                type="submit"
                variant="warning"
                @click="onClickAddNote()"
              />
            </f-col>
          </f-row>
          <b-list-group ref="noteList">
            <b-list-group-item
              v-for="(note, index) in bug.notes"
              :key="index"
              class="flex-column align-items-start"
              :class="{
                'list-group-item-success': !note.id
              }"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1 w-30">
                  <b>{{ note.createdBy.fullName }}</b>
                </h6>
                <small>
                  <b>{{ formatDateTime(note.createdAt) }}</b></small>
              </div>
              <div style="position: relative">
                <p
                  :ref="index"
                  class="w-85"
                  :style="{
                    whiteSpace: 'pre-line'
                  }"
                >
                  {{
                    index === isShowMore
                      ? note.text
                      : note.text.split(/\r\n|\r|\n/).length > 4
                        ? note.text
                          .split(/\r\n|\r|\n/)
                          .splice(0, 4)
                          .join('\n')
                        : note.text.slice(0, 360)
                  }}
                  <span
                    v-if="
                      note.text.length > 360 ||
                        note.text.split(/\r\n|\r|\n/).length > 4
                    "
                    class="ml-2 align-self-end text-secondary"
                    type="button"
                    @click="readMore(index)"
                  >
                    {{
                      isShowMore === index
                        ? $t('labels.hide')
                        : '...' + $t('labels.readMore')
                    }}
                  </span>
                </p>
                <div style="position: absolute; right: 5px; top: 2px">
                  <f-button
                    v-if="!bug.id || user.role === userRole.ADMIN || !note.id"
                    class="ml-2"
                    :disabled="!userHasEditPermission()"
                    icon="times"
                    pill
                    size="sm"
                    variant="outline-primary"
                    @click="onClickDeleteNote(index, note.id)"
                  />
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </f-card>
        <f-modal
          ref="modalUrgentMessage"
          cancel-disabled
          header-bg-variant="warning"
          header-text="center"
          size="sm"
          submit-disabled
          :title="$t('labels.warning')"
        >
          <template
            #footer
          >
            <f-button
              block
              :label="$t('labels.ok')"
              variant="warning"
              @click="closeUrgent"
            />
          </template>
          <div class="d-block text-center">
            <h4>
              {{ $t('messages.urgentMessage') }}
            </h4>
          </div>
        </f-modal>
      </f-row>
    </div>
    <h4
      v-else
      class="text-center"
    >
      {{ `Bu hata ${bug.updatedBy.fullName} tarafından ${this.$flex.dateService.getDateTime(bug.deletedAt)} tarihinde silinmiştir` }}
    </h4>
  </f-card>
</template>

<script>
import { attachmentColumns } from '@/data/columns'
import { bugStatus, userRole } from '@/data/enums'
import { mapGetters } from 'vuex'

export default {
  props: {
    bug: { type: Object },
    files: { type: Array }
  },
  data() {
    return {
      attachmentColumns,
      search: {},
      userRole,
      addNoteText: '',
      currentDate: '',
      isShowMore: null
    }
  },
  computed: {
    ...mapGetters(['user']),

  },
  created() {
    this.currentDate = new Date()
  },
  updated() {
    this.isDeleteAttachmentDisabled()
  },
  mounted() {
    this.isDeleteAttachmentDisabled()
  },
  methods: {
    userHasEditPermission(inputLabel) {
      const { id, status } = this.bug
      const { role } = this.user

      if (role === userRole.ADMIN || !id) {
        return true
      }

      if (role === userRole.USER) {
        if ([
          'subject',
          'content',
          'isUrgent'
        ].includes(inputLabel)) {
          return [
            bugStatus.WAITING,
          ].includes(status)
        }

        return [
          bugStatus.WAITING,
          bugStatus.REJECTED,
          bugStatus.TESTING,
          bugStatus.APPROVED
        ].includes(status)
      }

      return false
    },
    readMore(key) {
      if (this.isShowMore !== key) {
        this.isShowMore = key
      } else {
        this.isShowMore = null
      }
    },
    isDeleteAttachmentDisabled() {
      const length = window.$('#bugAttachmentTable')[0]?.rows.length

      for (let index = 1; index < length; index++) {
        if (
          !!window
            .$('#bugAttachmentTable')[0]
            .rows[index].cells[1]?.getElementsByTagName('a')[0] &&
          this.user.role !== this.userRole.ADMIN
        ) {
          window
            .$('#bugAttachmentTable')[0]
            .rows[index].cells[0].children.item(0).style.display = 'none'
        }
      }
    },
    uploadAttachment(BugAttachmentList) {
      for (const attachment of BugAttachmentList) {
        this.bug.attachments.unshift({
          name: attachment.name,
          createdBy: {
            fullName: this.user.fullName
          },
          createdAt: this.currentDate
        })
        this.files.unshift(attachment)
      }
    },
    onClickAddNote() {
      if (this.addNoteText !== null) {
        this.bug.notes.unshift({
          text: this.addNoteText,
          createdBy: {
            fullName: this.user.fullName
          },
          createdAt: new Date()
        })
      }

      this.addNoteText = ''
    },
    onClickDeleteNote(index) {
      this.bug.notes.splice(index, 1)
    },
    onChangeStatus(value) {
      if (value === true) {
        this.$refs.modalUrgentMessage.show()
      }
    },
    closeUrgent() {
      this.$refs.modalUrgentMessage.hide()
    },
    onDeleteAttachment(row) {
      const attachIndex = this.bug.attachments.findIndex(
        x => x.name === row.name
      )
      const fileIndex = this.files.findIndex(file => file.name === row.name)

      this.bug.attachments.splice(attachIndex, 1)
      this.files.splice(fileIndex, 1)
    },
    formatDateTime(date) {
      return this.$flex.dateService.getDateTime(date)
    }
  }
}
</script>

<style lang="scss">
.bugAttachmentTable {
  table {
    border-left: none;
    border-right: none;
    th {
      border-left: none;
      border-right: none;
    }
    tbody {
      & > tr {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        & > td {
          border: none;
        }
      }
    }
  }
}
.table-view {
  .card-body {
    padding: 1rem 0;
  }
  .fixed-table-toolbar {
    padding: 0 1.5rem;
  }
}
</style>
