var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f-page',{attrs:{"title":_vm.$t(("labels." + (this.$route.name)))}},[_c('f-form',{on:{"submit":_vm.onSubmitSearch}},[_c('f-card',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('f-button',{staticClass:"float-right",attrs:{"icon":"search","label":_vm.$t('labels.search'),"type":"submit","variant":"primary"}}),_c('f-button',{staticClass:"float-right mr-2",attrs:{"icon":"eraser","t-label":"reset","variant":"light"},on:{"click":_vm.clearSearch}})]},proxy:true}])},[_c('f-row',[_c('f-col',{attrs:{"lg":this.$route.name === 'pendingBugs' ? 3 : 4,"md":"6","sm":"3"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.bugNo')},model:{value:(_vm.search.id),callback:function ($$v) {_vm.$set(_vm.search, "id", $$v)},expression:"search.id"}})],1),_c('f-col',{attrs:{"lg":this.$route.name === 'pendingBugs' ? 3 : 4,"md":"6","sm":"3"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.subject')},model:{value:(_vm.search.subject),callback:function ($$v) {_vm.$set(_vm.search, "subject", $$v)},expression:"search.subject"}})],1),_c('f-col',{attrs:{"lg":this.$route.name === 'pendingBugs' ? 3 : 4,"md":"6","sm":"3"}},[_c('f-input',{attrs:{"label":_vm.$t('labels.fullName')},model:{value:(_vm.search.fullName),callback:function ($$v) {_vm.$set(_vm.search, "fullName", $$v)},expression:"search.fullName"}})],1),(this.$route.name === 'pendingBugs')?_c('f-col',{attrs:{"lg":"3","md":"6","sm":"3"}},[_c('f-select',{attrs:{"label":_vm.$t('labels.status'),"options":_vm.statuses,"value-type":"string"},model:{value:(_vm.search.status),callback:function ($$v) {_vm.$set(_vm.search, "status", $$v)},expression:"search.status"}})],1):_vm._e()],1)],1)],1),_c('f-card',{staticClass:"table-view"},[_c('f-datatable',{ref:"bugsDatatable",staticClass:"bugsTable table-center",attrs:{"actions":_vm.actionsByRole,"columns":_vm.columns,"data-url":"/bugs","features":{
          create: _vm.isCreateBugEnabled,
          update: true,
          delete: { visibility: _vm.bugDeleteButtonVisibility },
          pagination: _vm.isPaginationEnabled
        },"options":{
          reorderableRows: _vm.isReorder,
          useRowAttrFunc: _vm.isReorder,
          onReorderRowsDrag: _vm.reorderDrag,
          onReorderRowsDrop: _vm.reorderDrop,
          rowStyle: _vm.readBugsRowStyle
        },"query-params":_vm.search},on:{"action":_vm.onClickOpenBugStatusEdit,"create":_vm.onClickCreateBug,"delete":_vm.onClickDeleteBug,"update":_vm.onClickUpdateBug}},[_c('template',{slot:"toolbar-right"},[_c('f-button',{attrs:{"label":_vm.$t('labels.sortingOperations'),"variant":"warning"},on:{"click":_vm.onClickShowOperations}})],1)],2)],1),(this.$route.name === 'pendingBugs')?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('messages.draggableBug'))+" ")]):_vm._e(),_c('f-modal',{ref:"modalBugEdit",attrs:{"id":_vm.selectedBug.id ? 'updateBug' : 'createBug',"cancel-label":_vm.selectedBug.deletedAt
          ? _vm.$t('labels.close')
          : _vm.$t('labels.cancel'),"footer-tag":"footer","scrollable":"","submit-disabled":_vm.submitDisabledRule,"title":_vm.modalBugEditTitle},on:{"hide":_vm.hideBugEditModal,"submit":_vm.saveBug}},[(_vm.selectedBug.id)?_c('template',{slot:"header-right"},[(_vm.selectedBug)?_c('f-user-edit-info',{attrs:{"data":_vm.selectedBug}}):_vm._e()],1):_vm._e(),_c('modal-bug-edit',{attrs:{"bug":_vm.selectedBug,"files":_vm.files}}),(_vm.selectedBug.status === _vm.bugStatus.TESTING)?_c('template',{slot:"footer-left"},[_c('f-button',{attrs:{"label":_vm.$t('labels.approveTest'),"variant":"success"},on:{"click":_vm.openModalApproveTest}})],1):_vm._e()],2),_c('f-modal',{ref:"modalTestApproveMessage",attrs:{"cancel-label":_vm.$t('labels.no'),"header-bg-variant":"warning","size":"sm","submit-label":_vm.$t('labels.yes'),"title":_vm.$t('labels.warning')},on:{"submit":_vm.onClickApproveTest}},[_c('div',{staticClass:"d-block text-center"},[_c('h5',[_vm._v(" Testinizi tamamlayıp onayladıktan sonra yazılım geliştirmesi canlıya alınacaktır. Emin misiniz? ")])])]),_c('f-modal',{ref:"modalBugStatus",attrs:{"title":_vm.$t('labels.bugStatus')},on:{"submit":_vm.updateStatus}},[(_vm.selectedBug.id)?_c('template',{slot:"header-right"},[(_vm.selectedBug)?_c('f-user-edit-info',{attrs:{"data":_vm.selectedBug}}):_vm._e()],1):_vm._e(),_c('modal-bug-edit-status',{attrs:{"bug":_vm.selectedBug}})],2),_c('f-modal',{ref:"modalBugSortingOperation",attrs:{"cancel-label":_vm.$t('labels.close'),"scrollable":"","submit-disabled":"","title":_vm.$t('labels.operations')}},[_c('modal-bug-sorting-operation')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }