<template>
  <div>
    <f-page :title="$t(`labels.${this.$route.name}`)">
      <f-form @submit="onSubmitSearch">
        <f-card>
          <f-row>
            <f-col
              :lg="this.$route.name === 'pendingBugs' ? 3 : 4"
              md="6"
              sm="3"
            >
              <f-input
                v-model="search.id"
                :label="$t('labels.bugNo')"
              />
            </f-col>
            <f-col
              :lg="this.$route.name === 'pendingBugs' ? 3 : 4"
              md="6"
              sm="3"
            >
              <f-input
                v-model="search.subject"
                :label="$t('labels.subject')"
              />
            </f-col>
            <f-col
              :lg="this.$route.name === 'pendingBugs' ? 3 : 4"
              md="6"
              sm="3"
            >
              <f-input
                v-model="search.fullName"
                :label="$t('labels.fullName')"
              />
            </f-col>
            <f-col
              v-if="this.$route.name === 'pendingBugs'"
              lg="3"
              md="6"
              sm="3"
            >
              <f-select
                v-model="search.status"
                :label="$t('labels.status')"
                :options="statuses"
                value-type="string"
              />
            </f-col>
          </f-row>
          <template
            #footer
          >
            <f-button
              class="float-right"
              icon="search"
              :label="$t('labels.search')"
              type="submit"
              variant="primary"
            />
            <f-button
              class="float-right mr-2"
              icon="eraser"
              t-label="reset"
              variant="light"
              @click="clearSearch"
            />
          </template>
        </f-card>
      </f-form>

      <f-card class="table-view">
        <f-datatable
          ref="bugsDatatable"
          :actions="actionsByRole"
          class="bugsTable table-center"
          :columns="columns"
          data-url="/bugs"
          :features="{
            create: isCreateBugEnabled,
            update: true,
            delete: { visibility: bugDeleteButtonVisibility },
            pagination: isPaginationEnabled
          }"
          :options="{
            reorderableRows: isReorder,
            useRowAttrFunc: isReorder,
            onReorderRowsDrag: reorderDrag,
            onReorderRowsDrop: reorderDrop,
            rowStyle: readBugsRowStyle
          }"
          :query-params="search"
          @action="onClickOpenBugStatusEdit"
          @create="onClickCreateBug"
          @delete="onClickDeleteBug"
          @update="onClickUpdateBug"
        >
          <template slot="toolbar-right">
            <f-button
              :label="$t('labels.sortingOperations')"
              variant="warning"
              @click="onClickShowOperations"
            />
          </template>
        </f-datatable>
      </f-card>
      <p
        v-if="this.$route.name === 'pendingBugs'"
        class="text-center"
      >
        {{ $t('messages.draggableBug') }}
      </p>
      <f-modal
        :id="selectedBug.id ? 'updateBug' : 'createBug'"
        ref="modalBugEdit"
        :cancel-label="
          selectedBug.deletedAt
            ? $t('labels.close')
            : $t('labels.cancel')
        "
        footer-tag="footer"
        scrollable
        :submit-disabled="submitDisabledRule"
        :title="
          modalBugEditTitle
        "
        @hide="hideBugEditModal"
        @submit="saveBug"
      >
        <template
          v-if="selectedBug.id"
          slot="header-right"
        >
          <f-user-edit-info
            v-if="selectedBug"
            :data="selectedBug"
          />
        </template>
        <modal-bug-edit
          :bug="selectedBug"
          :files="files"
        />
        <template
          v-if="selectedBug.status === bugStatus.TESTING"
          slot="footer-left"
        >
          <f-button
            :label="$t('labels.approveTest')"
            variant="success"
            @click="openModalApproveTest"
          />
        </template>
      </f-modal>
      <f-modal
        ref="modalTestApproveMessage"
        :cancel-label="$t('labels.no')"
        header-bg-variant="warning"
        size="sm"
        :submit-label="$t('labels.yes')"
        :title="$t('labels.warning')"
        @submit="onClickApproveTest"
      >
        <div class="d-block text-center">
          <h5>
            Testinizi tamamlayıp onayladıktan sonra yazılım geliştirmesi canlıya
            alınacaktır. Emin misiniz?
          </h5>
        </div>
      </f-modal>
      <f-modal
        ref="modalBugStatus"
        :title="$t('labels.bugStatus')"
        @submit="updateStatus"
      >
        <template
          v-if="selectedBug.id"
          slot="header-right"
        >
          <f-user-edit-info
            v-if="selectedBug"
            :data="selectedBug"
          />
        </template>
        <modal-bug-edit-status :bug="selectedBug" />
      </f-modal>
      <f-modal
        ref="modalBugSortingOperation"
        :cancel-label="$t('labels.close')"
        scrollable
        submit-disabled
        :title="$t('labels.operations')"
      >
        <modal-bug-sorting-operation />
      </f-modal>
    </f-page>
  </div>
</template>

<script>
import {
  bugColumns,
  bugResolvedColumns,
  bugRejectedColumns
} from '@/data/columns'
import ModalBugEdit from './ModalBugEdit'
import ModalBugEditStatus from './ModalBugEditStatus'
import ModalBugSortingOperation from './ModalBugSortingOperation'
import { bugStatus, userRole } from '@/data/enums'
import { mapGetters } from 'vuex'

export default {
  components: { ModalBugEdit, ModalBugEditStatus, ModalBugSortingOperation },
  data() {
    return {
      bugColumns,
      bugResolvedColumns,
      bugRejectedColumns,
      bugStatus,
      userRole,
      search: {},
      files: [],
      selectedBug: {},
      unmodifiedBug: {},
      reorderRows: {},
      submitDisabledRule: false,
      statuses: [
        { id: bugStatus.WAITING, name: this.$i18n.t('labels.waitingBug') },
        { id: bugStatus.APPROVED, name: this.$i18n.t('labels.approvedBug') },
        {
          id: bugStatus.IN_PROGRESS,
          name: this.$i18n.t('labels.inProgress')
        },
        { id: bugStatus.TESTING, name: this.$i18n.t('labels.testing') },
        {
          id: bugStatus.TEST_APPROVED,
          name: this.$i18n.t('labels.testApproved')
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['user', 'selectedProjectId', 'selectedCompanyId']),
    actionsByRole() {
      const action = {
        name: 'bugStatus',
        icon: 'wrench',
        title: this.$t('labels.changeStatus')
      }

      return this.$store.getters.user.role === userRole.ADMIN ? [action] : []
    },
    bugProjectName() {
      return this.selectedBug.project
        ? ` - ${this.selectedBug.project.name}`
        : ''
    },
    isReorder() {
      return ['pendingBugs'].includes(this.$route.name)
    },
    isCreateBugEnabled() {
      return !(this.selectedProjectId?.length > 0) && this.selectedProjectId
    },
    isPaginationEnabled() {
      return !['pendingBugs'].includes(this.$route.name)
    },
    modalBugEditTitle() {
      if (this.selectedBug.deletedAt) {
        return this.$t('labels.deletedBug')
      }

      return this.selectedBug.id
        ? this.$t('labels.editBug')
        : this.$t('labels.createBug')
    }
  },
  created() {
    this.setStatusFilter()
    this.loadFromMailReq()
    this.search.projectId = this.selectedProjectId
  },
  watch: {
    selectedProjectId() {
      this.search.projectId = this.selectedProjectId
      this.$refs.bugsDatatable.refresh()
    }
  },
  methods: {
    hideBugEditModal(event) {
      const { type, trigger } = event;
      const hasUnsavedChanges = JSON.stringify(this.selectedBug) !== this.unmodifiedBug;
      const isHideEvent = type === 'hide';
      const isTriggered = trigger !== null;

      if (hasUnsavedChanges && isHideEvent && isTriggered) {
        event.preventDefault();

        const confirmationMessage = this.selectedBug.notes.length > JSON.parse(this.unmodifiedBug).notes.length
          ? this.$t('messages.unsavedNoteChanges')
          : this.$t('messages.unsavedChanges');

        this.$flex.modal.confirm(
          confirmationMessage,
          {
            size: 'md',
            okTitle: this.$t('labels.yes'),
            cancelTitle: this.$t('labels.no'),
            okVariant: 'danger',
            cancelVariant: 'light'
          }
        ).then(({ trigger }) => {
          if (trigger === 'ok') {
            this.$refs.modalBugEdit.hide();
          }
        });
      }
    },
    bugDeleteButtonVisibility(row) {
      if (this.user.role !== userRole.ADMIN) {
        return [bugStatus.WAITING, bugStatus.APPROVED].includes(row.status)
      }

      return true
    },
    reorderDrag(row) {
      this.reorderRows.bugId = row.id
      this.reorderRows.previousPriority = row.priority
      this.reorderRows.status = row.status
    },
    async reorderDrop(row) {
      this.reorderRows.newPriority = row.priority ? row.priority : 1

      this.reorderRows.companyId = this.selectedCompanyId

      if (this.reorderRows.previousPriority !== null) {
        await this.$http.put('/bugs/order', this.reorderRows)
      } else {
        this.$flex.notification.info(
          this.$t('messages.notAllowedToMakeChanges')
        )
      }

      this.$refs.bugsDatatable.refresh()
    },
    async loadFromMailReq() {
      const id = this.$route.query.id

      if (id) {
        this.selectedBug = await this.$http.get(`/bugs/${id}`, {
          params: {
            isFromMail: true
          }
        })
        this.submitDisabledRule = !!this.selectedBug.deletedAt
        this.unmodifiedBug = JSON.stringify(this.selectedBug)
        this.$refs.modalBugEdit.show()
        window.history.pushState({}, document.title, window.location.pathname)
      }
    },
    async saveBug() {
      if (JSON.stringify(this.selectedBug) === this.unmodifiedBug) {
        return this.$refs.modalBugEdit.hide()
      }

      const formData = new FormData()

      for (const file of this.files) {
        formData.append('files', file)
      }

      this.selectedBug.url = this.getUrlForBugsEmails()
      formData.append('bug', JSON.stringify(this.selectedBug))

      if (this.selectedBug.id) {
        await this.$http.put(`/bugs/${this.selectedBug.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      } else {
        await this.$http.post('/bugs', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }

      this.$refs.modalBugEdit.hide()
      this.$refs.bugsDatatable.refresh()
      this.$flex.notification.success(this.$t('messages.saveSuccess'))
      this.files = []
    },
    async onClickCreateBug() {
      this.selectedBug = {
        projectId: this.selectedProjectId,
        url: this.getUrlForBugsEmails(),
        attachments: [],
        notes: [],
        companyId: this.selectedCompanyId,
        isUrgent: false,
      }
      this.unmodifiedBug = JSON.stringify(this.selectedBug)
      this.$refs.modalBugEdit.show()
    },
    async onClickUpdateBug(row) {
      this.selectedBug = await this.$http.get(`/bugs/${row.id}`)
      this.unmodifiedBug = JSON.stringify(this.selectedBug)

      if (this.user.role === this.userRole.ADMIN) {
        this.submitDisabledRule = false
      } else {
        const statusRules = [
          bugStatus.WAITING,
          bugStatus.TESTING,
          bugStatus.APPROVED,
          bugStatus.REJECTED
        ]

        this.submitDisabledRule = !statusRules.includes(row.status)
      }

      this.$refs.modalBugEdit.show()
    },
    async onClickDeleteBug(row) {
      await this.$http.delete(`/bugs/${row.id}`, {
        data: { companyId: this.selectedCompanyId }
      })
      this.$refs.bugsDatatable.refresh()
    },
    onSubmitSearch() {
      if (!this.search.status) {
        this.setStatusFilter()
      }

      this.$refs.bugsDatatable.refresh()
    },
    clearSearch() {
      this.search = { projectId: this.selectedProjectId, status: '' }
    },
    openModalApproveTest() {
      this.$refs.modalTestApproveMessage.show()
    },
    async onClickApproveTest() {
      const status = { status: this.bugStatus.TEST_APPROVED }

      status.url = this.getUrlForBugsEmails()
      await this.$http.put(`/bugs/${this.selectedBug.id}/testApproved`, status)
      this.$refs.modalTestApproveMessage.hide()
      this.$refs.modalBugEdit.hide()
      this.$flex.notification.success(
        this.$t('messages.changedStatusToTestApproved')
      )
      this.$refs.bugsDatatable.refresh()
    },
    async onClickOpenBugStatusEdit(row) {
      this.selectedBug = await this.$http.get(`/bugs/${row.id}`)
      this.selectedBug.companyId = this.selectedCompanyId

      if (this.selectedBug.stagingLink) {
        const repository = await this.$http.get('/projects/repositories', {
          params: {
            stagingUrl: this.selectedBug.stagingLink
          }
        })

        this.selectedBug.staging = repository[0]?.id
      }

      if (this.selectedBug.prodLink) {
        const repository = await this.$http.get('/projects/repositories', {
          params: {
            productionUrl: this.selectedBug.prodLink
          }
        })

        this.selectedBug.prod = repository[0]?.id
      }

      this.unmodifiedBug = JSON.stringify(this.selectedBug)
      this.$refs.modalBugStatus.show()
    },
    async updateStatus() {
      if (this.unmodifiedBug === JSON.stringify(this.selectedBug)) {
        return this.$refs.modalBugStatus.hide()
      }

      this.setNullDateIfEmpty()

      this.selectedBug.url = this.getUrlForBugsEmails()
      await this.$http.put(
        `/bugs/${this.selectedBug.id}/status`,
        this.selectedBug
      )
      this.$refs.modalBugStatus.hide()
      this.$refs.bugsDatatable.refresh()
      this.$flex.notification.success(this.$t('messages.saveSuccess'))
    },
    async setStatusFilter() {
      const routeName = this.$route.name
      let statusList = []

      if (routeName === 'pendingBugs') {
        statusList = [
          bugStatus.WAITING,
          bugStatus.APPROVED,
          bugStatus.IN_PROGRESS,
          bugStatus.TESTING,
          bugStatus.TEST_APPROVED
        ]
        this.columns = bugColumns
      } else if (routeName === 'rejectedBugs') {
        statusList = [bugStatus.REJECTED]
        this.columns = bugRejectedColumns
      } else if (routeName === 'resolvedBugs') {
        statusList = [bugStatus.DONE]
        this.columns = bugResolvedColumns
      }

      if (statusList.length > 0) {
        this.search.status = statusList.join(',')
      }
    },
    onClickShowOperations() {
      this.$refs.modalBugSortingOperation.show()
    },
    readBugsRowStyle(row) {
      return (
        row.isUrgent === true && {
          css: { 'background-color': 'pink' }
        }
      )
    },
    getUrlForBugsEmails() {
      return (
        window.location.origin +
        '/' +
        window.location.pathname.split('/')[1] +
        '/' +
        window.location.pathname.split('/')[2]
      )
    },
    setNullDateIfEmpty() {
      if (!this.selectedBug.startDate) {
        this.selectedBug.startDate = null
      }

      if (!this.selectedBug.endDate) {
        this.selectedBug.endDate = null
      }
    },
  }
}
</script>

<style lang="scss">
.dragDrop .custom-file-label {
  height: 100px;
  line-height: 100px;
}
.dragDrop .custom-file,
.dragDrop .custom-file-input {
  height: 100px;
}

.bugsTable {
  table {
    border-left: none;
    border-right: none;
    th {
      border-left: none;
      border-right: none;

      &:nth-of-type(3) {
        text-align: start;
      }
      &:nth-of-type(4) {
        text-align: start;
      }
    }
    tbody {
      & > tr {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        & > td {
          border:none;

          &:nth-of-type(3) {
            text-align: start;
          }
          &:nth-of-type(4) {
            text-align: start;
          }

          &:nth-of-type(5) {
                display: flex;
                justify-content: center;
          }
        }
      }
    }
  }
}
.table-view{
  .card-body{
    padding: 2rem 0;
  }
  .fixed-table-toolbar{
    padding: 0 1.5rem;
  }
  .fixed-table-pagination{
    padding: 0 1.5rem;
  }
}
</style>
