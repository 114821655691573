<template>
  <f-card class="bug-operation-modal-card">
    <f-datatable
      ref="bugSortingOperationsDatatable"
      class="bug-sorting-operations-table"
      :columns="bugSortingOperationsColumns"
      data-url="/bugs/sortingOperations"
      :features="{ fullscreen: false, showColumns: false }"
      :query-params="{ projectId: selectedProjectId }"
    />
  </f-card>
</template>

<script>
import { bugSortingOperationsColumns } from '@/data/columns'
import { mapGetters } from 'vuex'

export default {
  data() {
    return { bugSortingOperationsColumns }
  },
  computed: {
    ...mapGetters(['selectedProjectId']),
  }
}
</script>

<style lang="scss" >
.bug-operation-modal-card{
  .card-body {
    padding: 0 !important;
  }
  .fixed-table-pagination {
    padding: 0 1rem;
  }
}
.bug-sorting-operations-table {
  table {
    border-left: none;
    border-right: none;
    th {
      border-left: none;
      border-right: none;
      &:nth-of-type(1) {
        text-align: center;
      }
    }
    tbody {
      & > tr {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        & > td {
          border: none;
          &:nth-of-type(1) {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
